
import { AnyAction } from "redux"
import { ADMIN_FAQ_DATA_FAIL, ADMIN_FAQ_DATA_REQ, ADMIN_FAQ_DATA_SUCC, ADMIN_FAQ_DATA_SUCC_SEARCH } from "redux/actions/admin/faq/type"
 

interface State {
    loading?: boolean
    data?: any,
    error?: string,
    search?: any,


}

const defaultState: State = {
    loading: false,
    data: [],
    search:[]


}


// export const ADMIN_FAQ_DATA_FAIL="ADMIN_FAQ_DATA_FAIL"
// export const ADMIN_FAQ_DATA_REQ="ADMIN_FAQ_DATA_REQ"
// export const ADMIN_FAQ_DATA_SUCC="ADMIN_FAQ_DATA_SUCC"
export const getAdminFaqReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_FAQ_DATA_REQ:

            return {
                loading: true,
                data: state.data,
                search:state.search
            }
        case ADMIN_FAQ_DATA_SUCC:

            return {
                loading: false,
                data: action.payload,
                search:action.payload


            }
        case ADMIN_FAQ_DATA_FAIL:
            return {
                loading: false,
                error: action.payload
            }
            case ADMIN_FAQ_DATA_SUCC_SEARCH:
            return {
                error: action.payload,
                data: state.data,
                search: action.payload
            }

           
        default:
            return state;
    }
}