
import { AnyAction } from "redux"
import { CUSTOMERS_DATA_FILTER_DATES, CUSTOMERS_DATA_FILTER_LOCATION } from "redux/actions/customerSubscriber/type"
import { TRANSACTIONS_DATA_FILTER_LOCATION } from "redux/actions/transactions/type"




interface State {
    data?:any


    location?: string | null
}

const defaultState: State = {

    data:[new Date(new Date().setDate(new Date().getDate()-7)), new Date()],

    location: null
}

export const allTransactionsFilter = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case CUSTOMERS_DATA_FILTER_DATES:
            return {
                data: action.payload,

                location: state.location
            }
        case TRANSACTIONS_DATA_FILTER_LOCATION:
            return {
                data: state.data,

                location: action.payload
            }
        default:
            return state;
    }
}