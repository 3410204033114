import { AnyAction } from "redux"
import { CUSTOMERS_OPEN, DASHBOARD_OPEN, HELP_OPEN, LOCATION_OPEN, PAYMENT_OPEN, PRODUCTS_OPEN, PROFILE_OPEN, TRANSACTION_OPEN } from "redux/constants/LeftNav"


interface State {
    dashboard:boolean
    location:boolean
    products:boolean
    transactions:boolean
    customer:boolean
    help:boolean
    profile:boolean
    payment:boolean
}

const defaultState : State ={
    dashboard: true,
    location: false,
    products:false,
    transactions:false,
    customer:false,
    help:false,
    profile:false,
    payment:false
}


export const LeftNavOpenerReducer = (state:State = defaultState, action:AnyAction):State => {
    switch(action.type){
        case DASHBOARD_OPEN :
            return {
                dashboard: true,
                location: false,
                products:false,
                transactions:false,
                customer:false,
                help:false,
                profile:false,
                payment:false
            }

        case LOCATION_OPEN :
            return {
                dashboard: false,
                location: true,
                products:false,
                transactions:false,
                customer:false,
                help:false,
                profile:false,
                payment:false


            }

        case PRODUCTS_OPEN :
            return {
                dashboard: false,
                location: false,
                products:true,
                transactions:false,
                customer:false,
                help:false,
                profile:false,
                payment:false

            }
        case TRANSACTION_OPEN :
            return {
                dashboard: false,
                location: false,
                products:false,
                transactions:true,
                customer:false,
                help:false,
                profile:false,
                payment:false

            }

            case CUSTOMERS_OPEN :
                return {
                    dashboard: false,
                    location: false,
                    products:false,
                    transactions:false,
                    customer:true,
                    help:false,
                    profile:false,
                    payment:false

                }

            case HELP_OPEN:
                return{
                    dashboard: false,
                    location: false,
                    products:false,
                    transactions:false,
                    customer:false,
                    help:true,
                    profile:false,
                    payment:false

                }
            
            case PROFILE_OPEN:
                return{
                    dashboard: false,
                    location: false,
                    products:false,
                    transactions:false,
                    customer:false,
                    help:false,
                    profile:true,
                    payment:false
                }

                case PAYMENT_OPEN:
                    return{
                        dashboard: false,
                        location: false,
                        products:false,
                        transactions:false,
                        customer:false,
                        help:false,
                        profile:false,
                        payment:true
                    }

            default:
                return state
    }
}