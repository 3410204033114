
import { format } from "date-fns";
import { AnyAction } from "redux"
 import { TRANSACTIONS_CHART_DATA_FILTER_DATA, TRANSACTIONS_CHART_DATA_FILTER_DATES } from "redux/actions/transactions/type";


interface State {
    date?: string | Date,
    filterType?: number,
  
     
}
let date = new Date()
let olddate = new Date()
olddate.setDate(olddate.getDate() - 1);
let finalDate = `${format(olddate, "yyyy-MM-dd")}:${format(date, "yyyy-MM-dd")}`
const defaultState: State = {
    date: finalDate,
    filterType: 0,
 
     
}

export const transactionsChartFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case TRANSACTIONS_CHART_DATA_FILTER_DATES:
  
            return {
                date: action.payload,
                filterType: action.active,
               
                
            }
        
            


             
        default:
            return state;
    }
}