 

export const FAQ_DATA_FAIL="FAQ_DATA_FAIL"
export const FAQ_DATA_REQ="FAQ_DATA_REQ"
export const FAQ_DATA_SUCC="FAQ_DATA_SUCC"
export const FAQ_DATA_SUCC_SEARCH="FAQ_DATA_SUCC_SEARCH"

 
 

 