
import { AnyAction } from "redux"
import { ADMIN_SUBSCRIBERS_DATA_FAIL, ADMIN_SUBSCRIBERS_DATA_NEW_SUCC, ADMIN_SUBSCRIBERS_DATA_REQ, ADMIN_SUBSCRIBERS_DATA_SUCC, ADMIN_SUBSCRIBERS_DATA_SUCC_SEARCH } from "redux/actions/admin/subscribers/type"
 
  

interface State {
    loading?: boolean
    data?: any,
    error?: string,
     
    


}

const defaultState: State = {
    loading: false,
    data: [],
   
    


}

 

 

// export const ADMIN_SUBSCRIBERS_DATA_FAIL="ADMIN_SUBSCRIBERS_DATA_FAIL"
// export const ADMIN_SUBSCRIBERS_DATA_REQ="ADMIN_SUBSCRIBERS_DATA_REQ"
// export const ADMIN_SUBSCRIBERS_DATA_SUCC="ADMIN_SUBSCRIBERS_DATA_SUCC"


 


export const getAdminSubscriberReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_SUBSCRIBERS_DATA_REQ:

            return {
                loading: true,
                data: state.data 
                 
            }
        case ADMIN_SUBSCRIBERS_DATA_SUCC:

            return {
                loading: false,
                data:[...state.data,...action.payload]
                 
            }
            case ADMIN_SUBSCRIBERS_DATA_NEW_SUCC:
                return {
                    loading:false,
                    data:action.payload
                }

            case ADMIN_SUBSCRIBERS_DATA_SUCC_SEARCH:
                return {
                      
                    data:state.data,
                     

                }
        case ADMIN_SUBSCRIBERS_DATA_FAIL:
            return {
                loading: false,
                error: action.payload,
                data:state.data,
                 
            }
            

           
        default:
            return state;
    }
}