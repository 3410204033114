 
import { AnyAction } from "redux"
 import { GET_ALL_TRANSACTIONS_FAIL, GET_ALL_TRANSACTIONS_REQ, GET_ALL_TRANSACTIONS_SUCC } from "redux/actions/transactions/type"
 
  

interface State {
    loading?: boolean
    data?: any,
    error?: string,
     

}

const defaultState: State = {
    loading: false,
    data: [],
    

}

// export const GET_ALL_TRANSACTIONS_REQ="GET_ALL_TRANSACTIONS_REQ"
// export const GET_ALL_TRANSACTIONS_SUCC="GET_ALL_TRANSACTIONS_SUCC"
// export const GET_ALL_TRANSACTIONS_FAIL="GET_ALL_TRANSACTIONS_FAIL"

export const getAllTransactionsReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case GET_ALL_TRANSACTIONS_REQ:

            return {
                loading: true,
                data:[]
            }
        case GET_ALL_TRANSACTIONS_SUCC:
            
            return {
                loading: false,
                data: action.payload,
                 
            }
        case GET_ALL_TRANSACTIONS_FAIL :
            return {
                loading: false,
                error: action.payload
            }
 

        default:
            return state;
    }
}