 

export const NOTIFICATIONS_DATA_FAIL="NOTIFICATIONS_DATA_FAIL"
export const NOTIFICATIONS_DATA_REQ="NOTIFICATIONS_DATA_REQ"
export const NOTIFICATIONS_DATA_SUCC="NOTIFICATIONS_DATA_SUCC"


export const NOTIFICATIONS_COUNT_DATA_FAIL="NOTIFICATIONS_COUNT_DATA_FAIL"
export const NOTIFICATIONS_COUNT_DATA_REQ="NOTIFICATIONS_COUNT_DATA_REQ"
export const NOTIFICATIONS_COUNT_DATA_SUCC="NOTIFICATIONS_COUNT_DATA_SUCC"
 

 