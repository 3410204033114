 
import { AnyAction } from "redux"
import { CATEGORIES_DATA_CUSTOMER_FAIL, CATEGORIES_DATA_CUSTOMER_REQ, CATEGORIES_DATA_CUSTOMER_SUCC, PRODUCTS_DATA_CUSTOMER_FAIL, PRODUCTS_DATA_CUSTOMER_REQ, PRODUCTS_DATA_CUSTOMER_SUCC, TRASACTION_DATA_CUSTOMER_FAIL, TRASACTION_DATA_CUSTOMER_REQ, TRASACTION_DATA_CUSTOMER_SUCC } from "redux/actions/customer/products/type"
 
  

interface State {
    loading?: boolean
    data?: any,
    error?: string,
     

}

const defaultState: State = {
    loading: false,
    data: [],
    

}

 
// export const TRASACTION_DATA_CUSTOMER_FAIL="TRASACTION_DATA_CUSTOMER_FAIL"
// export const TRASACTION_DATA_CUSTOMER_REQ="TRASACTION_DATA_CUSTOMER_REQ"
// export const TRASACTION_DATA_CUSTOMER_SUCC="TRASACTION_DATA_CUSTOMER_SUCC"

export const getTransationByPhoneReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case TRASACTION_DATA_CUSTOMER_REQ:

            return {
                loading: true,
                data:state.data
            }
        case TRASACTION_DATA_CUSTOMER_SUCC:
            
            return {
                loading: false,
                data: action.payload,
                 
            }
        case TRASACTION_DATA_CUSTOMER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
 

        default:
            return state;
    }
}