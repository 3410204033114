 

export const ADMIN_LOCATIONS_DATA_FAIL="ADMIN_LOCATIONS_DATA_FAIL"
export const ADMIN_LOCATIONS_DATA_REQ="ADMIN_LOCATIONS_DATA_REQ"
export const ADMIN_LOCATIONS_DATA_SUCC="ADMIN_LOCATIONS_DATA_SUCC"


 
// CHART_DATA_FILTER_DATA, CHART_DATA_FILTER_DATES, CHART_DATA_FILTER_LOCATIONID 

export const CHART_ADMIN_LOCATIONS_DATA_FILTER_DATA="CHART_ADMIN_LOCATIONS_DATA_FILTER_DATA"

export const CHART_ADMIN_LOCATIONS_DATA_FILTER_DATES="CHART_ADMIN_LOCATIONS_DATA_FILTER_DATES"

export const CHART_ADMIN_LOCATIONS_DATA_FILTER_LOCATIONID="CHART_ADMIN_LOCATIONS_DATA_FILTER_LOCATIONID"

// CHART_DATA_FAIL, CHART_DATA_REQ, CHART_DATA_SUCC 


export const ADMIN_LOCATIONS_CHART_DATA_FAIL="ADMIN_LOCATIONS_CHART_DATA_FAIL"


export const ADMIN_LOCATIONS_CHART_DATA_REQ="ADMIN_LOCATIONS_CHART_DATA_REQ"


export const ADMIN_LOCATIONS_CHART_DATA_SUCC="ADMIN_LOCATIONS_CHART_DATA_SUCC"


 



export const ADMIN_LOCATIONS_DATA_SUCC_SEARCH="ADMIN_LOCATIONS_DATA_SUCC_SEARCH"



export const ADMIN_LOCATIONS_DATA_SEARCH="ADMIN_LOCATIONS_DATA_SEARCH"


export const ADMIN_LOCATIONS_DATA_FILTER="ADMIN_LOCATIONS_DATA_FILTER"
 



export const GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_REQ = 'GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_REQ';
export const GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_SUCC = 'GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_SUCC';
export const GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_FAIL = 'GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_FAIL';