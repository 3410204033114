 
import { AnyAction } from "redux"
import { CATEGORIES_DATA_CUSTOMER_FAIL, CATEGORIES_DATA_CUSTOMER_REQ, CATEGORIES_DATA_CUSTOMER_SUCC, PRODUCTS_DATA_CUSTOMER_FAIL, PRODUCTS_DATA_CUSTOMER_REQ, PRODUCTS_DATA_CUSTOMER_SUCC } from "redux/actions/customer/products/type"
import { PRODUCTS_DATA_FAIL, PRODUCTS_DATA_REQ, PRODUCTS_DATA_SUCC } from "redux/actions/product/type"
  

interface State {
    loading?: boolean
    data?: any,
    error?: string,
     

}

const defaultState: State = {
    loading: false,
    data: [],
    

}

 
// export const PRODUCTS_DATA_FAIL="PRODUCTS_DATA_FAIL"
// export const PRODUCTS_DATA_REQ="PRODUCTS_DATA_REQ"
// export const PRODUCTS_DATA_SUCC="PRODUCTS_DATA_SUCC"

export const getCategoriesForCustomerReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case CATEGORIES_DATA_CUSTOMER_REQ:

            return {
                loading: true,
                ...state
            }
        case CATEGORIES_DATA_CUSTOMER_SUCC:
            
            return {
                loading: false,
                data: action.payload,
                 
            }
        case CATEGORIES_DATA_CUSTOMER_FAIL:
            return {
                loading: false,
                error: action.payload
            }
 

        default:
            return state;
    }
}