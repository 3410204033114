
import { AnyAction } from "redux"
import { ADMIN_EMPLOYES_DATA_FAIL, ADMIN_EMPLOYES_DATA_REQ, ADMIN_EMPLOYES_DATA_SUCC, ADMIN_EMPLOYES_DATA_SUCC_SEARCH } from "redux/actions/admin/employes/type"
import { ADMIN_PRODUCTS_DATA_FAIL, ADMIN_PRODUCTS_DATA_REQ, ADMIN_PRODUCTS_DATA_SUCC, ADMIN_PRODUCTS_DATA_SUCC_SEARCH } from "redux/actions/admin/products/type"
 
  

interface State {
    loading?: boolean
    data?: any,
    error?: string,
    search?:any
    


}

const defaultState: State = {
    loading: false,
    data: [],
    search:[]
    


}

 

 

// export const ADMIN_PRODUCTS_DATA_FAIL="ADMIN_PRODUCTS_DATA_FAIL"
// export const ADMIN_PRODUCTS_DATA_REQ="ADMIN_PRODUCTS_DATA_REQ"
// export const ADMIN_PRODUCTS_DATA_SUCC="ADMIN_PRODUCTS_DATA_SUCC"


 


export const getAdminProductsReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_PRODUCTS_DATA_REQ:

            return {
                loading: true,
                data: state.data,
                search:state.search,
                 
            }
        case ADMIN_PRODUCTS_DATA_SUCC:

            return {
                loading: false,
                data: action.payload,
                search: action.payload,
            }

            case ADMIN_PRODUCTS_DATA_SUCC_SEARCH:
                return {
                      
                    data:state.data,
                    search:action.payload

                }
        case ADMIN_PRODUCTS_DATA_FAIL:
            return {
                loading: false,
                error: action.payload,
                data:state.data,
                search:state.search,
            }
            

           
        default:
            return state;
    }
}