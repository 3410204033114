import { FakeLocation, SingleLocation } from "demo/FakeLocations"
import { AnyAction } from "redux"
import { GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_FAIL, GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_REQ, GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_SUCC } from "redux/actions/admin/location/type"
 

interface State {
    loading: boolean
    data?:SingleLocation|null
    error?:string
}

const defaultState :State = {
    loading:false,
    data:null
}
// export const GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_REQ = 'GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_REQ';
// export const GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_SUCC = 'GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_SUCC';
// export const GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_FAIL = 'GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_FAIL';
export const getAdminSingleLocationWithStatsReducer = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_REQ:
            return {
                loading:true
            }
        case GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_SUCC:
            return {
                loading:false,
                data:action.payload
            }
        case GET_SINGLE_SELECTED_ADMIN_LOCATION_WITH_STATS_FAIL:
            return {
                loading: false,
                error:action.payload
            }

        default:
            return state;
    }
}