
import { AnyAction } from "redux"
import { PRODUCTS_ADMIN_DATA_FILTER_DATES, SUBSCRIBERS_ADMIN_DATA_FILTER_DATES } from "redux/actions/admin/filters/types";
import { ADMIN_SUBSCRIBERS_DATA_FILTER } from "redux/actions/admin/subscribers/type";
 
 

interface State {

    date: string | false,
    country: string,
    state: string,
    city: string,
    active: any,
    userActive:any,
    fiterDate: string | false



}

const defaultState: State = {
    date: false,
    country: "",
    state: "",
    city: "",
    active: { allTime: true, lastMonth: false, lastWeek: false },
    fiterDate: false,
    userActive: false,



 
}



// export const ADMIN_EMPLOYES_DATA_FAIL="ADMIN_EMPLOYES_DATA_FAIL"
// export const ADMIN_EMPLOYES_DATA_REQ="ADMIN_EMPLOYES_DATA_REQ"
// export const ADMIN_EMPLOYES_DATA_SUCC="ADMIN_EMPLOYES_DATA_SUCC"


export const getAdminSubscribersFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_SUBSCRIBERS_DATA_FILTER:

            return {
                userActive: action.userActive,
                date: action.date,
                country: action.country,
                state: action.state,
                city: action.city,
                active: state.active,
                fiterDate: state.fiterDate


            }
            case SUBSCRIBERS_ADMIN_DATA_FILTER_DATES:
           
                return {
                    userActive: state.userActive,
                    active: action.active,
                    fiterDate: action.payload,
                    date: state.date,
                    country: state.country,
                    state: state.state,
                    city: state.city,
    
                }
    

        default:
            return state;
    }
}