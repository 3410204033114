 
import { AnyAction } from "redux"
import { LOCATIONS_DATA_END, LOCATIONS_DATA_FAIL, LOCATIONS_DATA_NEW_SUCC, LOCATIONS_DATA_REQ, LOCATIONS_DATA_SUCC } from "../../actions/location/type";


interface State {
    loading?:boolean
    data?:any,
    error?:string
}

const defaultState :State = {
    loading:false,
    data:[]
}

export const getLocationsReducer = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case LOCATIONS_DATA_REQ:
            return {
                loading:true,
                data:state.data
            }
        case LOCATIONS_DATA_SUCC:
            return {
                loading:false,
                data:[...state.data,...action.payload]
            }
            case LOCATIONS_DATA_NEW_SUCC:
                return {
                    loading:false,
                    data:action.payload
                }
        case LOCATIONS_DATA_FAIL:
            return {
                loading: false,
                error:action.payload,
                data:state.data
            }


            

         
        default:
            return state;
    }
}