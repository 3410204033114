 

export const ADMIN_LOCATIONSQR_DATA_FAIL="ADMIN_LOCATIONSQR_DATA_FAIL"
export const ADMIN_LOCATIONSQR_DATA_REQ="ADMIN_LOCATIONSQR_DATA_REQ"
export const ADMIN_LOCATIONSQR_DATA_SUCC="ADMIN_LOCATIONSQR_DATA_SUCC"


 
 

export const ADMIN_LOCATIONSQR_DATA_SUCC_SEARCH="ADMIN_LOCATIONSQR_DATA_SUCC_SEARCH"



export const ADMIN_LOCATIONSQR_DATA_SEARCH="ADMIN_LOCATIONSQR_DATA_SEARCH"


export const ADMIN_LOCATIONSQR_DATA_FILTER="ADMIN_LOCATIONSQR_DATA_FILTER"
// export const ADMIN_LOCATIONSQR_DATA_ROLE="ADMIN_LOCATIONSQR_DATA_ROLE"
// export const ADMIN_LOCATIONSQR_DATA_TYPE="ADMIN_LOCATIONSQR_DATA_TYPE"