
import { AnyAction } from "redux"
import { PRODUCTS_DATA_CUSTOMER_FAIL,PRODUCTS_DATA_CUSTOMER_FILTER_NAME, PRODUCTS_DATA_CUSTOMER_REQ, PRODUCTS_DATA_CUSTOMER_SUCC } from "redux/actions/customer/products/type"


interface State {
    loading?: boolean
    data?: any,
    error?: string,
    products?: any

}

const defaultState: State = {
    loading: false,
    data: [],
    products: []


}


// export const PRODUCTS_DATA_FAIL="PRODUCTS_DATA_FAIL"
// export const PRODUCTS_DATA_REQ="PRODUCTS_DATA_REQ"
// export const PRODUCTS_DATA_SUCC="PRODUCTS_DATA_SUCC"

export const getProductsForCustomerReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case PRODUCTS_DATA_CUSTOMER_REQ:

            return {
                loading: true,
                data: state.data,
                products: state.products

            }
        case PRODUCTS_DATA_CUSTOMER_SUCC:

            return {
                loading: false,
                data: action.payload.stock,
                products: action.payload

            }
        case PRODUCTS_DATA_CUSTOMER_FAIL:
            return {
                loading: false,
                data: state.data,
                products: state.products
            }
        case PRODUCTS_DATA_CUSTOMER_FILTER_NAME:
            return {
                loading: false,
                data:action.payload,
                products:state.products
            }
        
        default:
            return state;
    }
}