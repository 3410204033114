
import { AnyAction } from "redux"
import { NOTIFICATIONS_DATA_FAIL, NOTIFICATIONS_DATA_REQ, NOTIFICATIONS_DATA_SUCC } from "redux/actions/notifications/type"
import { PAYMENT_METHOD_DATA_FAIL, PAYMENT_METHOD_DATA_REQ, PAYMENT_METHOD_DATA_SUCC, PLANS_DATA_FAIL, PLANS_DATA_REQ, PLANS_DATA_SUCC, PLANS_HISTORY_DATA_FAIL, PLANS_HISTORY_DATA_REQ, PLANS_HISTORY_DATA_SUCC } from "redux/actions/user/Type"


interface State {
    loading?: boolean
    data?: any,
    error?: string,


}

const defaultState: State = {
    loading: false,
    data: false,


}








// export const PAYMENT_METHOD_DATA_REQ = "PAYMENT_METHOD_DATA_REQ"
// export const PAYMENT_METHOD_DATA_SUCC = "PAYMENT_METHOD_DATA_SUCC"
// export const PAYMENT_METHOD_DATA_FAIL = "PAYMENT_METHOD_DATA_FAI
 
 


export const getPaymentMethodStatusReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case PAYMENT_METHOD_DATA_REQ:

            return {
                loading: true,
                data: state.data
            }
        case PAYMENT_METHOD_DATA_SUCC:

            return {
                loading: false,
                data: action.payload,

            }
        case PAYMENT_METHOD_DATA_FAIL:
            return {
                loading: false,
                error: action.payload
            }


        default:
            return state;
    }
}