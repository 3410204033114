 
export const PRODUCT_CATEGORY_DATA_FAIL="PRODUCT_CATEGORY_DATA_FAIL"
export const PRODUCT_CATEGORY_DATA_REQ="PRODUCT_CATEGORY_DATA_REQ"
export const PRODUCT_CATEGORY_DATA_SUCC="PRODUCT_CATEGORY_DATA_SUCC"
 
export const PRODUCT_BY_LOCATION_DATA_FAIL="PRODUCT_BY_LOCATION_DATA_FAIL"
export const PRODUCT_BY_LOCATION_DATA_REQ="PRODUCT_BY_LOCATION_DATA_REQ"
export const PRODUCT_BY_LOCATION_DATA_SUCC="PRODUCT_BY_LOCATION_DATA_SUCC"

export const PRODUCTS_FAIL="PRODUCTS_FAIL"
export const PRODUCTS_REQ="PRODUCTS_REQ"
export const PRODUCTS_SUCC="PRODUCTS_SUCC"

export const PRODUCTS_DATA_FAIL="PRODUCTS_DATA_FAIL"
export const PRODUCTS_DATA_REQ="PRODUCTS_DATA_REQ"
export const PRODUCTS_DATA_SUCC="PRODUCTS_DATA_SUCC"

export const PRODUCTS_SEARCH_FAIL="PRODUCTS_SEARCH_FAIL"
export const PRODUCTS_SEARCH_RESET="PRODUCTS_SEARCH_RESET"
export const PRODUCTS_SEARCH_SUCC="PRODUCTS_SEARCH_SUCC"

export const SALES_DATA_FAIL="SALES_DATA_FAIL"
export const SALES_DATA_REQ="SALES_DATA_REQ"
export const SALES_DATA_SUCC="SALES_DATA_SUCC"

export const CHART_DATA_FILTER_DATES="CHART_DATA_FILTER_DATES"

export const CHART_DATA_FILTER_DATA="CHART_DATA_FILTER_DATA"
export const CHART_DATA_FILTER_LOCATIONID="CHART_DATA_FILTER_LOCATIONID"
 

export const CHART_DATA_FAIL="CHART_DATA_FAIL"
export const CHART_DATA_REQ="CHART_DATA_REQ"
export const CHART_DATA_SUCC="CHART_DATA_SUCC"

export const PRODUCTS_SALES_STATUS="PRODUCTS_SALES_STATUS"