 
import { AnyAction } from "redux"
import { USER_DATA_FAIL, USER_DATA_REQ, USER_DATA_SUCC } from "redux/actions/user/Type"



interface State {
    loading?:boolean
    data?:any,
    error?:string
}

const defaultState :State = {
    loading:false,
    data:null
}

export const getUserReducer = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case USER_DATA_REQ:
            return {
                loading:true,
                data:state.data
            }
        case USER_DATA_SUCC:
            return {
                loading:false,
                data:action.payload
            }
        case USER_DATA_FAIL:
            return {
                loading: false,
                error:action.payload
            }

        default:
            return state;
    }
}