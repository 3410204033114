 
import { AnyAction } from "redux"
import { CUSTOMERS_DATA_FILTER_DATES,CUSTOMERS_DATA_FILTER_LOCATION } from "redux/actions/customerSubscriber/type"
 
 


interface State {
    data?:string|boolean
    active?:any,
    location?:string|null
}

const defaultState :State = {
    
    data:false,
    active:{allTime:true, lastMonth:false, lastWeek:false},
    location:null
}

export const singleCustomerStatsFilter = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case CUSTOMERS_DATA_FILTER_DATES:
            return {
                data:action.payload,
                 active:action.active,
                 location:state.location
            }
            case CUSTOMERS_DATA_FILTER_LOCATION:
                return {
                    data:state.data,
                     active:state.active,
                     location:action.payload
                }
        default:
            return state;
    }
}