 
import { AnyAction } from "redux"
import { SET_SINGLE_LOCATION_SATAS_FILTER } from "redux/constants/Location"
 


interface State {
    data?:string|boolean
    active?:any
}

const defaultState :State = {
    
    data:false,
    active:{allTime:true, lastMonth:false, lastWeek:false}
}

export const singleLocationStatsFilter = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case SET_SINGLE_LOCATION_SATAS_FILTER:
            return {
                data:action.payload,
                 active:action.active
            }
        default:
            return state;
    }
}