
import { AnyAction } from "redux"
import { ADMIN_LOCATIONSQR_DATA_FILTER } from "redux/actions/admin/QrCodes/type"


interface State {

    date: string | false,
    country: string,
    state: string,
    city: string,



}

const defaultState: State = {
    date: false,
    country: "",
    state: "",
    city: ""




}



// export const ADMIN_EMPLOYES_DATA_FAIL="ADMIN_EMPLOYES_DATA_FAIL"
// export const ADMIN_EMPLOYES_DATA_REQ="ADMIN_EMPLOYES_DATA_REQ"
// export const ADMIN_EMPLOYES_DATA_SUCC="ADMIN_EMPLOYES_DATA_SUCC"


export const getAdminLoationsQrFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_LOCATIONSQR_DATA_FILTER:

            return {
                date: action.date,
                country: action.country,
                state: action.state,
                city: action.city,

            }


        default:
            return state;
    }
}