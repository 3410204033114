import { FakeLocation, SingleLocation } from "demo/FakeLocations"
import { AnyAction } from "redux"
import { GET_SINGLE_SELECTED_ADMIN_LOCATION_FAIL, GET_SINGLE_SELECTED_ADMIN_LOCATION_REQ, GET_SINGLE_SELECTED_ADMIN_LOCATION_SUCC } from "redux/constants/admin/AdminLocations"


interface State {
    loading: boolean
    data?:SingleLocation|null
    error?:string
}

const defaultState :State = {
    loading:false,
    data:null
}

export const getAdminSingleLocationReducer = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case GET_SINGLE_SELECTED_ADMIN_LOCATION_REQ:
            return {
                loading:true
            }
        case GET_SINGLE_SELECTED_ADMIN_LOCATION_SUCC:
            return {
                loading:false,
                data:action.payload
            }
        case GET_SINGLE_SELECTED_ADMIN_LOCATION_FAIL:
            return {
                loading: false,
                error:action.payload
            }

        default:
            return state;
    }
}