 
import { AnyAction } from "redux"
import { ADMIN_SUBSCRIBERS_DATA_UPDATE, ADMIN_SUBSCRIBERS_PAGE_UPDATE } from "redux/actions/admin/subscribers/type"
 


interface State {
    
    page?:number,
    count?:number
}

const defaultState :State = {
    
    page:1,
    count:0,
}
// export const  ADMIN_SUBSCRIBERS_PAGE_UPDATE = "ADMIN_SUBSCRIBERS_PAGE_UPDATE"
// export const ADMIN_SUBSCRIBERS_DATA_UPDATE = " ADMIN_SUBSCRIBERS_DATA_UPDATE"
export const adminSubscribersPaginationReducer = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case ADMIN_SUBSCRIBERS_PAGE_UPDATE:
            return {
                  
                 count:state.count,
                 page:action.payload
            }
        case ADMIN_SUBSCRIBERS_DATA_UPDATE:
            return {
                 count:action.payload,
                 page:1
            }
        

        default:
            return state;
    }
}