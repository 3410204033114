import { FakeLocation, SingleLocation } from "demo/FakeLocations"
import { AnyAction } from "redux"
import { GET_SINGLE_SELECTED_LOCATION_FAIL, GET_SINGLE_SELECTED_LOCATION_REQ, GET_SINGLE_SELECTED_LOCATION_SUCC, SET_SINGLE_LOCATION_FOR_EDIT } from "redux/constants/Location"


interface State {
    loading: boolean
    data?: SingleLocation|null
    error?: string,
    id?: string
}

const defaultState: State = {
    loading: false,
    data: null,
    id: ""
}

export const GetSingleLocationReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case GET_SINGLE_SELECTED_LOCATION_REQ:
            return {
                loading: true,
                id: state.id,
                data: state.data

            }
        case GET_SINGLE_SELECTED_LOCATION_SUCC:
            return {
                loading: false,
                data: action.payload,
                id: action.payload._id

            }
        case GET_SINGLE_SELECTED_LOCATION_FAIL:
            return {
                loading: false,
                error: action.payload,
                id: state.id,
                data:state.data

            }
        case SET_SINGLE_LOCATION_FOR_EDIT:
            return {
                loading: false,
                data: state.data,
                id: action.payload

            }

        default:
            return state;
    }
}