
import { AnyAction } from "redux"
import { PRODUCTS_FAIL, PRODUCTS_REQ, PRODUCTS_SEARCH_RESET, PRODUCTS_SEARCH_SUCC, PRODUCTS_SUCC, PRODUCT_CATEGORY_DATA_FAIL, PRODUCT_CATEGORY_DATA_REQ, PRODUCT_CATEGORY_DATA_SUCC } from "../../actions/product/type";


interface State {
    loading?: boolean
    data?: any,
    error?: string,
    products: any,

}

const defaultState: State = {
    loading: false,
    data: [],
    products: [],

}

// export const PRODUCTS_FAIL="PRODUCTS_FAIL"
// export const PRODUCTS_REQ="PRODUCTS_REQ"
// export const PRODUCTS_SUCC="PRODUCTS_SUCC"
export const getProductsToAddLocation = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case PRODUCTS_REQ:

            return {
                loading: true,
                ...state
            }
        case PRODUCTS_SUCC:
            return {
                loading: false,
                data: action.payload,
                products: action.payload
            }
        case PRODUCTS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case PRODUCTS_SEARCH_SUCC:
            return {
                loading: false,
                data: action.payload,
                products: state.products
            }


        default:
            return state;
    }
}