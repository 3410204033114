export const  USER_DATA_REQ = "USER_DATA_REQ"
export const  USER_DATA_SUCC = " USER_DATA_SUCC"
export const  USER_DATA_FAIL = "USER_DATA_FAIL"
export const  USER_LOGIN_FLOW = "USER_LOGIN_FLOW"


export const PLANS_DATA_REQ = "PLANS_DATA_REQ"
export const PLANS_DATA_SUCC = "PLANS_DATA_SUCC"
export const PLANS_DATA_FAIL = "PLANS_DATA_FAIL"


export const PLANS_HISTORY_DATA_REQ = "PLANS_HISTORY_DATA_REQ"
export const PLANS_HISTORY_DATA_SUCC = "PLANS_HISTORY_DATA_SUCC"
export const PLANS_HISTORY_DATA_FAIL = "PLANS_HISTORY_DATA_FAIL"
 


export const PAYMENT_METHOD_DATA_REQ = "PAYMENT_METHOD_DATA_REQ"
export const PAYMENT_METHOD_DATA_SUCC = "PAYMENT_METHOD_DATA_SUCC"
export const PAYMENT_METHOD_DATA_FAIL = "PAYMENT_METHOD_DATA_FAIL"
