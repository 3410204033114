
import { AnyAction } from "redux"
import { ADMIN_EMPLOYES_DATA_FAIL, ADMIN_EMPLOYES_DATA_FILTER, ADMIN_EMPLOYES_DATA_REQ, ADMIN_EMPLOYES_DATA_SUCC } from "redux/actions/admin/employes/type"


interface State {

    date: string | false,
    employeType: string,
    role: string



}

const defaultState: State = {
    date: false,
    employeType:"all",
    role: "all"




}



// export const ADMIN_EMPLOYES_DATA_FAIL="ADMIN_EMPLOYES_DATA_FAIL"
// export const ADMIN_EMPLOYES_DATA_REQ="ADMIN_EMPLOYES_DATA_REQ"
// export const ADMIN_EMPLOYES_DATA_SUCC="ADMIN_EMPLOYES_DATA_SUCC"


export const getAdminEmployesFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_EMPLOYES_DATA_FILTER:

            return {
                date: action.date,
                employeType:action.employeType ,
                role: action.role

            }


        default:
            return state;
    }
}