export const GET_ALL_TRANSACTIONS_REQ="GET_ALL_TRANSACTIONS_REQ"
export const GET_ALL_TRANSACTIONS_SUCC="GET_ALL_TRANSACTIONS_SUCC"
export const GET_ALL_TRANSACTIONS_FAIL="GET_ALL_TRANSACTIONS_FAIL"

// export const  CUSTOMERS_DATA_FILTER_DATES= "CUSTOMERS_DATA_FILTER_DATES"
export const TRANSACTIONS_DATA_FILTER_LOCATION= "TRANSACTIONS_DATA_FILTER_LOCATION"

// CHART_DATA_FAIL, CHART_DATA_REQ, CHART_DATA_SUCC
export const TRANSACTIONS_CHART_DATA_FAIL="TRANSACTIONS_CHART_DATA_FAIL"
export const TRANSACTIONS_CHART_DATA_REQ="TRANSACTIONS_CHART_DATA_REQ"
export const TRANSACTIONS_CHART_DATA_SUCC="TRANSACTIONS_CHART_DATA_SUCC"


export const TRANSACTIONS_CHART_DATA_FILTER_DATES="TRANSACTIONS_CHART_DATA_FILTER_DATES"

export const TRANSACTIONS_CHART_DATA_FILTER_DATA="TRANSACTIONS_CHART_DATA_FILTER_DATA"
  