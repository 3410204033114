 

export const ADMIN_ALL_STATS_DATA_FAIL="ADMIN_ALL_STATS_DATA_FAIL"
export const ADMIN_ALL_STATS_DATA_REQ="ADMIN_ALL_STATS_DATA_REQ"
export const ADMIN_ALL_STATS_DATA_SUCC="ADMIN_ALL_STATS_DATA_SUCC"


 
export const ADMIN_ALL_STATS_FILTER="ADMIN_ALL_STATS_FILTER"




export const ADMIN_ALL_SUBSCRIBERS_STATS_DATA_FAIL="ADMIN_ALL_SUBSCRIBERS_STATS_DATA_FAIL"
export const ADMIN_ALL_SUBSCRIBERS_STATS_DATA_REQ="ADMIN_ALL_SUBSCRIBERS_STATS_DATA_REQ"
export const ADMIN_ALL_SUBSCRIBERS_STATS_DATA_SUCC="ADMIN_ALL_SUBSCRIBERS_STATS_DATA_SUCC"




export const ADMIN_PRODUCTS_DASHBOARD_DATA_FAIL="PRODUCTS_DASHBOARD_DATA_FAIL"
export const ADMIN_PRODUCTS_DASHBOARD_DATA_REQ="PRODUCTS_DASHBOARD_DATA_REQ"
export const ADMIN_PRODUCTS_DASHBOARD_DATA_SUCC="PRODUCTS_DASHBOARD_DATA_SUCC"


 

export const ADMIN_LOCATIONS_DASHBOARD_DATA_FAIL="ADMIN_LOCATIONS_DASHBOARD_DATA_FAIL"
export const ADMIN_LOCATIONS_DASHBOARD_DATA_REQ="ADMIN_LOCATIONS_DASHBOARD_DATA_REQ"
export const ADMIN_LOCATIONS_DASHBOARD_DATA_SUCC="ADMIN_LOCATIONS_DASHBOARD_DATA_SUCC"


export const ADMIN_SUBSCRIBERS_DASHBOARD_DATA_FAIL="ADMIN_SUBSCRIBERS_DASHBOARD_DATA_FAIL"
export const ADMIN_SUBSCRIBERS_DASHBOARD_DATA_REQ="ADMIN_SUBSCRIBERS_DASHBOARD_DATA_REQ"
export const ADMIN_SUBSCRIBERS_DASHBOARD_DATA_SUCC="ADMIN_SUBSCRIBERS_DASHBOARD_DATA_SUCC"

export const ADMIN_PLAN_DASHBOARD_DATA_FAIL="ADMIN_PLAN_DASHBOARD_DATA_FAIL"
export const ADMIN_PLAN_DASHBOARD_DATA_REQ="ADMIN_PLAN_DASHBOARD_DATA_REQ"
export const ADMIN_PLAN_DASHBOARD_DATA_SUCC="ADMIN_PLAN_DASHBOARD_DATA_SUCC"


export const ADMIN_PLAN_TRAIL_DASHBOARD_DATA_FAIL="ADMIN_PLAN_TRAIL_DASHBOARD_DATA_FAIL"
export const ADMIN_PLAN_TRAIL_DASHBOARD_DATA_REQ="ADMIN_PLAN_TRAIL_DASHBOARD_DATA_REQ"
export const ADMIN_PLAN_TRAIL_DASHBOARD_DATA_SUCC="ADMIN_PLAN_TRAIL_DASHBOARD_DATA_SUCC"




export const ADMIN_CMS_HEADER_DASHBOARD_DATA_FAIL="ADMIN_CMS_HEADER_DASHBOARD_DATA_FAIL"
export const ADMIN_CMS_HEADER_DASHBOARD_DATA_REQ="ADMIN_CMS_HEADER_DASHBOARD_DATA_REQ"
export const ADMIN_CMS_HEADER_DASHBOARD_DATA_SUCC="ADMIN_CMS_HEADER_DASHBOARD_DATA_SUCC"


export const ADMIN_CMS_CONTENT_DASHBOARD_DATA_FAIL="ADMIN_CMS_CONTENT_DASHBOARD_DATA_FAIL"
export const ADMIN_CMS_CONTENT_DASHBOARD_DATA_REQ="ADMIN_CMS_CONTENT_DASHBOARD_DATA_REQ"
export const ADMIN_CMS_CONTENT_DASHBOARD_DATA_SUCC="ADMIN_CMS_CONTENT_DASHBOARD_DATA_SUCC"

export const ADMIN_CMS_FOOTER_DASHBOARD_DATA_FAIL="ADMIN_CMS_FOOTER_DASHBOARD_DATA_FAIL"
export const ADMIN_CMS_FOOTER_DASHBOARD_DATA_REQ="ADMIN_CMS_FOOTER_DASHBOARD_DATA_REQ"
export const ADMIN_CMS_FOOTER_DASHBOARD_DATA_SUCC="ADMIN_CMS_FOOTER_DASHBOARD_DATA_SUCC"

export const ADMIN_CMS_BLOGS_DASHBOARD_DATA_FAIL="ADMIN_CMS_BLOGS_DASHBOARD_DATA_FAIL"
export const ADMIN_CMS_BLOGS_DASHBOARD_DATA_REQ="ADMIN_CMS_BLOGS_DASHBOARD_DATA_REQ"
export const ADMIN_CMS_BLOGS_DASHBOARD_DATA_SUCC="ADMIN_CMS_BLOGS_DASHBOARD_DATA_SUCC"

export const ADMIN_CMS_COUNT_DASHBOARD_DATA_FAIL="ADMIN_CMS_COUNT_DASHBOARD_DATA_FAIL"
export const ADMIN_CMS_COUNT_DASHBOARD_DATA_REQ="ADMIN_CMS_COUNT_DASHBOARD_DATA_REQ"
export const ADMIN_CMS_COUNT_DASHBOARD_DATA_SUCC="ADMIN_CMS_COUNT_DASHBOARD_DATA_SUCC"

