
import { AnyAction } from "redux"
import { ADMIN_ALL_STATS_DATA_FAIL, ADMIN_ALL_STATS_DATA_REQ, ADMIN_ALL_STATS_DATA_SUCC, ADMIN_ALL_STATS_FILTER } from "redux/actions/admin/dashboard/type"
import { ADMIN_EMPLOYES_DATA_FAIL, ADMIN_EMPLOYES_DATA_REQ, ADMIN_EMPLOYES_DATA_SUCC, ADMIN_EMPLOYES_DATA_SUCC_SEARCH } from "redux/actions/admin/employes/type"
  

interface State {
     id:number
     
    


}

const defaultState: State = {
    id:1
    
    


}

 


// export const ADMIN_ALL_STATS_DATA_FAIL="ADMIN_ALL_STATS_DATA_FAIL"
// export const ADMIN_ALL_STATS_DATA_REQ="ADMIN_ALL_STATS_DATA_REQ"
// export const ADMIN_ALL_STATS_DATA_SUCC="ADMIN_ALL_STATS_DATA_SUCC"

export const getAdminAllStatsFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_ALL_STATS_FILTER:

            return {
                id: action.payload,
              
                
            }
        
           
        default:
            return state;
    }
}