 

export const ADMIN_FAQ_DATA_FAIL="ADMIN_FAQ_DATA_FAIL"
export const ADMIN_FAQ_DATA_REQ="ADMIN_FAQ_DATA_REQ"
export const ADMIN_FAQ_DATA_SUCC="ADMIN_FAQ_DATA_SUCC"


 
 

export const ADMIN_FAQ_DATA_SUCC_SEARCH="ADMIN_FAQ_DATA_SUCC_SEARCH"