
import { AnyAction } from "redux"
import { PRODUCT_BY_LOCATION_DATA_FAIL, PRODUCT_BY_LOCATION_DATA_REQ, PRODUCT_BY_LOCATION_DATA_SUCC, PRODUCT_CATEGORY_DATA_FAIL, PRODUCT_CATEGORY_DATA_REQ, PRODUCT_CATEGORY_DATA_SUCC } from "../../actions/product/type";


interface State {
    loading?: boolean
    data?: any,
    error?: string,
    products:any,
    loadingProducts?:boolean
}

const defaultState: State = {
    loading: false,
    data: [],
    products:[],
    loadingProducts:false
}

export const getProductsCategoryReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case PRODUCT_CATEGORY_DATA_REQ:
            
            return {
                loading: true,
                ...state
            }
        case PRODUCT_CATEGORY_DATA_SUCC:
            return {
                loading: false,
                data: action.payload,
                products:state.products,
            }
        case PRODUCT_CATEGORY_DATA_FAIL:
            return {
                loading: false,
                error: action.payload
            }
         
        case PRODUCT_BY_LOCATION_DATA_REQ:
            return {
                products:state.products,
                loadingProducts: true,
                data:state.data
            }

            
        case PRODUCT_BY_LOCATION_DATA_SUCC:
            return {
                products:action.payload,
                loadingProducts: false,
                data:state.data
              
            }
        case PRODUCT_BY_LOCATION_DATA_FAIL:
            return {
                loading: false,
                error: action.payload,
                ...state
            }

        default:
            return state;
    }
}