
import { AnyAction } from "redux"
import { DASHBOARD_ADMIN_DATA_FILTER_DATES, PRODUCTS_ADMIN_DATA_FILTER_DATES, SUBSCRIBERS_ADMIN_DATA_FILTER_DATES } from "redux/actions/admin/filters/types";
import { ADMIN_SUBSCRIBERS_DATA_FILTER } from "redux/actions/admin/subscribers/type";
 
 

interface State {

   
    active: any,
    fiterDate: string | false



}

const defaultState: State = {
    active: { allTime: true, lastMonth: false, lastWeek: false },
    fiterDate: false




}



// export const ADMIN_EMPLOYES_DATA_FAIL="ADMIN_EMPLOYES_DATA_FAIL"
// export const ADMIN_EMPLOYES_DATA_REQ="ADMIN_EMPLOYES_DATA_REQ"
// export const ADMIN_EMPLOYES_DATA_SUCC="ADMIN_EMPLOYES_DATA_SUCC"


export const getAdminDashboardFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
       
            case DASHBOARD_ADMIN_DATA_FILTER_DATES:
           
                return {
                    active: action.active,
                    fiterDate: action.payload,
                    
                }
    

        default:
            return state;
    }
}