import { AnyAction } from "redux"

interface State {
    dark:boolean
}

const defaultState:State = {
    dark : localStorage.getItem("theme")==="dark" ? true : false
}

export const DarkModeReducer = (state:State = defaultState, actions:AnyAction) => {
    if(actions.type === "DARK_MODE"){
        console.log(defaultState);
        
        return {
            dark:!state.dark
        }
    } else{
        return state;
    }
}