
import { AnyAction } from "redux"
import { ADMIN_ALL_STATS_DATA_FAIL, ADMIN_ALL_STATS_DATA_REQ, ADMIN_ALL_STATS_DATA_SUCC } from "redux/actions/admin/dashboard/type"
import { ADMIN_EMPLOYES_DATA_FAIL, ADMIN_EMPLOYES_DATA_REQ, ADMIN_EMPLOYES_DATA_SUCC, ADMIN_EMPLOYES_DATA_SUCC_SEARCH } from "redux/actions/admin/employes/type"
  

interface State {
    loading?: boolean
    data?: any,
    error?: string,
    totalSales ?: any,
    totalEarnings ?: any,
     
    


}

const defaultState: State = {
    loading: false,
    data: [],
     totalSales :100000000,
    totalEarnings : 100000000
    
    
    


}

 


// export const ADMIN_ALL_STATS_DATA_FAIL="ADMIN_ALL_STATS_DATA_FAIL"
// export const ADMIN_ALL_STATS_DATA_REQ="ADMIN_ALL_STATS_DATA_REQ"
// export const ADMIN_ALL_STATS_DATA_SUCC="ADMIN_ALL_STATS_DATA_SUCC"

export const getAdminAllStatsReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_ALL_STATS_DATA_REQ:

            return {
                loading: true,
                data: state.data,
                
                
            }
        case ADMIN_ALL_STATS_DATA_SUCC:

            return {
                loading: false,
                data: action.payload,
                totalSales :action.totalSales,
                totalEarnings : action.totalEarnings
                 
            }

             
        case ADMIN_ALL_STATS_DATA_FAIL:
            return {
                loading: false,
                error: action.payload,
                data:state.data,
                totalSales :state.totalSales,
                totalEarnings : state.totalSales
            }
            

           
        default:
            return state;
    }
}