 
import { AnyAction } from "redux"
import { CUSTOMER_DATA_SAVE } from "redux/actions/customer/products/type"
   

interface State {
     
    data?: any,
     
     

}

const defaultState: State = {
    
    data: {},
    

}

 
// export const PRODUCTS_DATA_FAIL="PRODUCTS_DATA_FAIL"
// export const PRODUCTS_DATA_REQ="PRODUCTS_DATA_REQ"
// export const PRODUCTS_DATA_SUCC="PRODUCTS_DATA_SUCC"

export const saveCustomerDataReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case CUSTOMER_DATA_SAVE:

            return {
                data:action.payload
            }
        

        default:
            return state;
    }
}