
import { format } from "date-fns";
import { AnyAction } from "redux"
import { ADMIN_SUBSCRIBERS_CHART_DATA_FAIL, ADMIN_SUBSCRIBERS_CHART_DATA_REQ, ADMIN_SUBSCRIBERS_CHART_DATA_SUCC } from "redux/actions/admin/subscribers/type";



interface State {
    data?: any,
    loading?: boolean,
    label?: any,
    lastMonthCount?:number,
    lastYearCount?:number,
    totalCount?:number,

}

const defaultState: State = {
    data: [],
    label: [],
    loading: false,
    lastMonthCount: 0,
    lastYearCount: 0,
    totalCount: 0
}

// export const ADMIN_SUBSCRIBERS_CHART_DATA_FAIL="ADMIN_SUBSCRIBERS_CHART_DATA_FAIL"


// export const ADMIN_SUBSCRIBERS_CHART_DATA_REQ="ADMIN_SUBSCRIBERS_CHART_DATA_REQ"


// export const ADMIN_SUBSCRIBERS_CHART_DATA_SUCC="ADMIN_SUBSCRIBERS_CHART_DATA_SUCC"



export const adminSubscriberChartDataFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_SUBSCRIBERS_CHART_DATA_REQ:

            return {
                loading: true,
                data: state.data,
                label: state.label,
                lastMonthCount: state.lastMonthCount,
                lastYearCount: state.lastYearCount,
                totalCount: state.totalCount
            }
        case ADMIN_SUBSCRIBERS_CHART_DATA_SUCC:
            console.log(action)
            return {
                loading: false,
                data: action.payload,
                label: action.label,
                lastMonthCount: action.lastMonthCount,
                lastYearCount: action.lastYearCount,
                totalCount: action.totalCount
            }
        case ADMIN_SUBSCRIBERS_CHART_DATA_FAIL:
            return {
                error: "error",
                data: [],
                label: [],
                lastMonthCount: state.lastMonthCount,
                lastYearCount: state.lastYearCount,
                totalCount: state.totalCount
            }



        default:
            return state;
    }
}