 
import { AnyAction } from "redux"
import {USER_LOGIN_FLOW } from "redux/actions/user/Type"



interface State {
    
    data?:any,
    
}

const defaultState :State = {
    
    data:null
}

export const getSignUpReducer = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        
        case USER_LOGIN_FLOW:
            return {
                data:action.payload
            }
        

        default:
            return state;
    }
}