
import { format } from "date-fns";
import { AnyAction } from "redux"
import { CHART_ADMIN_LOCATIONS_DATA_FILTER_DATA, CHART_ADMIN_LOCATIONS_DATA_FILTER_DATES, CHART_ADMIN_LOCATIONS_DATA_FILTER_LOCATIONID } from "redux/actions/admin/location/type";
 

interface State {
    date?: string | Date,
    filterType?: number,
    filterData?: string,
    productId?:string|null
}
let date = new Date()
let olddate = new Date()
olddate.setDate(olddate.getDate() - 1);
let finalDate = `${format(olddate, "yyyy-MM-dd")}:${format(date, "yyyy-MM-dd")}`
const defaultState: State = {
    date: finalDate,
    filterType: 0,
    filterData: "sales",
    productId:null
}


// export const CHART_ADMIN_LOCATIONS_DATA_FILTER_DATA="CHART_ADMIN_LOCATIONS_DATA_FILTER_DATA"

// export const CHART_ADMIN_LOCATIONS_DATA_FILTER_DATES="CHART_ADMIN_LOCATIONS_DATA_FILTER_DATES"

// export const CHART_ADMIN_LOCATIONS_DATA_FILTER_LOCATIONID="CHART_ADMIN_LOCATIONS_DATA_FILTER_LOCATIONID"


export const adminLocationChartFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case CHART_ADMIN_LOCATIONS_DATA_FILTER_DATES:
  
            return {
                date: action.payload,
                filterType: action.active,
                filterData: state.filterData,
                productId:state.productId
            }
        case CHART_ADMIN_LOCATIONS_DATA_FILTER_DATA:
            return {
                date: state.date,
                filterType: state.filterType,
                filterData: action.payload,
                productId:state.productId
            }
            case CHART_ADMIN_LOCATIONS_DATA_FILTER_LOCATIONID:
                
            return {
                date: state.date,
                filterType: state.filterType,
                filterData: state.filterData,
                productId:action.payload
            }


             
        default:
            return state;
    }
}