 
import { AnyAction } from "redux"
import { LOCATIONS_DATA_UPDATE, LOCATIONS_PAGE_UPDATE, LOCATIONS_SORT_UPDATE } from "../../actions/location/type";


interface State {
    sort?:string
    page?:number,
    count?:number
}

const defaultState :State = {
    sort:"all",
    page:1,
    count:0,
}

export const locationsPaginationReducer = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case LOCATIONS_PAGE_UPDATE:
            return {
                 sort:state.sort,
                 count:state.count,
                 page:action.payload
            }
        case LOCATIONS_SORT_UPDATE:
            return {
                sort:action.payload,
                 count:state.count,
                 page:1
            }
        case LOCATIONS_DATA_UPDATE:
            return {
                sort:state.sort,
                count:action.payload,
                page:state.page
            }

        default:
            return state;
    }
}