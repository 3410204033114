/**
 * Combine all reducers in this file and export the combined reducers.
 */
import { persistReducer } from 'redux-persist'
import { combineReducers } from '@reduxjs/toolkit';
import { getAdminSingleLocationReducer } from 'redux/reducers/admin/AdminLocations';
import { GetSingleSubscriberReducer } from 'redux/reducers/admin/AdminSubscriber';
import { cartReducer } from 'redux/reducers/customer/cartReducer';
import { getSingleCustomerReducer } from 'redux/reducers/CustomersReducer';
import { DarkModeReducer } from 'redux/reducers/DarkModeReducer';
import { LeftNavOpenerReducer } from 'redux/reducers/LeftNavOpenerReducer';
import { GetSingleLocationReducer } from 'redux/reducers/LocationReducer';
import { TopNavAdminOpenerReducer } from 'redux/reducers/TopNavAdmin';
import storage from 'redux-persist/lib/storage'
import { InjectedReducersType } from 'utils/types/injector-typings';
import { getUserReducer } from 'redux/reducers/user/UserReducer';
import { getSignUpReducer } from 'redux/reducers/user/SignUpReducer';
import { getLocationsReducer } from 'redux/reducers/location/LocationsReducer';
import { getProductsCategoryReducer } from 'redux/reducers/product/ProductCategoryReducer';
import { locationsPaginationReducer } from 'redux/reducers/location/paginationReducer';
import { getProductsToAddLocation } from 'redux/reducers/product/ProductsToAddLocation';
import { singleLocationStatsFilter } from 'redux/reducers/location/LocationStatsFilter';
import { getSalesStatus } from 'redux/reducers/product/SalesReducer';
import { getProductsReducer } from 'redux/reducers/product/Products';
import { productsChartFilterReducer } from 'redux/reducers/product/ChartFilter';
import { productsChartDataFilterReducer } from 'redux/reducers/product/ChartReducer';
import { ProductsSalesStatus } from 'redux/reducers/product/productsFilterStatus';
import { getProductsForCustomerReducer } from 'redux/reducers/customer/products';
import { getCategoriesForCustomerReducer } from 'redux/reducers/customer/categories';
import { saveCustomerDataReducer } from 'redux/reducers/customer/customerData';
import { getTransationByPhoneReducer } from 'redux/reducers/customer/transactions';
import { getSubscriberCustomersReducer } from 'redux/reducers/customerSubscriber/customers';
import { singleCustomerStatsFilter } from 'redux/reducers/customerSubscriber/customersFilter';
import { getAllTransactionsReducer } from 'redux/reducers/transactions/transactions';
import { allTransactionsFilter } from 'redux/reducers/transactions/transactionsFilter';
import { transactionsChartDataFilterReducer } from 'redux/reducers/transactions/ChartReducer';
import { transactionsChartFilterReducer } from 'redux/reducers/transactions/ChartFilter';
import { getProductsDashboardReducer } from 'redux/reducers/dashboard/Products';
import { getlocationsDashboardReducer } from 'redux/reducers/dashboard/ProductsLocation';
import { getNotificationsReducer } from 'redux/reducers/notifications/Products';
import { getNotificationsCountsReducer } from 'redux/reducers/notifications/ProductsCount';
import { getPlansReducer } from 'redux/reducers/user/Plans';
import { getPlansHistoryReducer } from 'redux/reducers/user/PlansHistory';
import { getPaymentMethodStatusReducer } from 'redux/reducers/user/PaymentStatus';
import { getFaqReducer } from 'redux/reducers/faq/faq';
import { getAdminFaqReducer } from 'redux/reducers/admin/faq/AdminFaq';
import { getAdminEmployesReducer } from 'redux/reducers/admin/employes/AdminEmployes';
import { getAdminEmployesFilterReducer } from 'redux/reducers/admin/employes/AdminEmployesFilter';
import { getAdminLocationsQrReducer } from 'redux/reducers/admin/locationsqr/AdminLocationsQr';
import { getAdminLoationsQrFilterReducer } from 'redux/reducers/admin/locationsqr/AdminLocationsQrFilter';
import { getAdminLocationsReducer } from 'redux/reducers/admin/locations/AdminLocations';
import { getAdminLocationsFilterReducer } from 'redux/reducers/admin/locations/AdminLocationsFilter';
import { getAdminProductsReducer } from 'redux/reducers/admin/products/AdminProducts';
import { getAdminProductsFilterReducer } from 'redux/reducers/admin/products/AdminProdctsFilter';
import { adminLocationChartFilterReducer } from 'redux/reducers/admin/locations/ChartFilter';
import { adminLocationChartDataFilterReducer } from 'redux/reducers/admin/locations/ChartReducer';
import { getAdminSingleLocationWithStatsReducer } from 'redux/reducers/admin/locations/LocationWithStatsReducer';
import { getAdminSubscriberReducer } from 'redux/reducers/admin/subscribers/AdminSubscriber';
import { getAdminSubscribersFilterReducer } from 'redux/reducers/admin/subscribers/AdminSubscriberFilter';
import { adminSubscriberChartFilterReducer } from 'redux/reducers/admin/subscribers/ChartFilter';
import { adminSubscriberChartDataFilterReducer } from 'redux/reducers/admin/subscribers/ChartReducer';
import { getAdminSingleSubscriberWithStatsReducer } from 'redux/reducers/admin/subscribers/SubscriberWithStatsReducer';
import { getAdminAllStatsReducer } from 'redux/reducers/admin/dashboard/AdminAllStats';
import { getAdminAllStatsFilterReducer } from 'redux/reducers/admin/dashboard/AdminAllStatsFilter';
import { getAdminAllSubscriberStatsReducer } from 'redux/reducers/admin/dashboard/AdminAllSubscriberStats';
import { getAdminProductsDashboardReducer } from 'redux/reducers/admin/dashboard/AdminProducts';
import { getAdminlocationsDashboardReducer } from 'redux/reducers/admin/dashboard/AdminLocation';
import { getAdminSubscribersDashboardReducer } from 'redux/reducers/admin/dashboard/AdminSubscribers';
import { getAdminPlansDashboardReducer } from 'redux/reducers/admin/dashboard/AdminPlan';
import { getAdminPlansTrailDashboardReducer } from 'redux/reducers/admin/dashboard/AdminPlanTrail';
import { getAdminCMSHeaderDashboardReducer } from 'redux/reducers/admin/dashboard/AdminCMSHeader';
import { getAdminCMSContentDashboardReducer } from 'redux/reducers/admin/dashboard/AdminCMSContent';
import { getAdminCMSFooterDashboardReducer } from 'redux/reducers/admin/dashboard/AdminCMSFooter';
import { getAdminCMSBlogsDashboardReducer } from 'redux/reducers/admin/dashboard/AdminCMSBlogs';
import { adminSubscribersPaginationReducer } from 'redux/reducers/admin/subscribers/paginationReducer';
import { getAdminCMSCountDashboardReducer } from 'redux/reducers/admin/dashboard/AdminCMSCount';
import { getAdminDashboardFilterReducer } from 'redux/reducers/admin/dashboard/AdminSubscriberFilter';


const persistConfig = {
  key: 'me',
  storage,
}
const persistConfig2 = {
  key: 'flow',
  storage,
}

const persistConfig3 = {
  key: 'customer',
  storage,
}




const persistConfig4 = {
  key: 'bavBar',
  storage,
}


const persistConfig5 = {
  key: 'paymentStatus',
  storage,
}
const persistConfig6 = {
  key: 'adminNav',
  storage,
}
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export function createReducer(injectedReducers: InjectedReducersType = {}) {
  // Initially we don't have any injectedReducers, so returning identity function to avoid the error
  // if (Object.keys(injectedReducers).length === 0) {
  //   return state => state;
  // } else {
  return combineReducers({
    ...injectedReducers,
    LeftNavOpener: persistReducer(persistConfig4, LeftNavOpenerReducer),
    GetSingleLocation: GetSingleLocationReducer,
    getSingleCustomer: getSingleCustomerReducer,
    DarkMode: DarkModeReducer,
    TopNavAdminOpener:  persistReducer(persistConfig6, TopNavAdminOpenerReducer),
    GetSingleSubscriber: GetSingleSubscriberReducer,
    getAdminSingleLocation: getAdminSingleLocationReducer,
    cart: cartReducer,
    user: persistReducer(persistConfig, getUserReducer),
    flow: persistReducer(persistConfig2, getSignUpReducer),
    locations: getLocationsReducer,
    productCategories: getProductsCategoryReducer,
    locationPagination: locationsPaginationReducer,
    productsForLocation: getProductsToAddLocation,
    singleLocationStatsFilter: singleLocationStatsFilter,
    sales: getSalesStatus,
    products: getProductsReducer,
    productChartFilter: productsChartFilterReducer,
    ProductChartData: productsChartDataFilterReducer,
    ProductsSalesStatus: ProductsSalesStatus,
    plans: getPlansReducer,
    PlansHistory: getPlansHistoryReducer,
    paymentMethodStatus: persistReducer(persistConfig5, getPaymentMethodStatusReducer),
    faq: getFaqReducer,

    // customer reducers 

    productsForCustomer: getProductsForCustomerReducer,
    categoriesForCustomer: getCategoriesForCustomerReducer,
    customerData: persistReducer(persistConfig3, saveCustomerDataReducer),
    transactionsData: getTransationByPhoneReducer,

    // subscribers customers reducers

    subcriberCustomer: getSubscriberCustomersReducer,
    singleCustomerStatsFilter: singleCustomerStatsFilter,


    // transactions reducer

    allTransactions: getAllTransactionsReducer,
    allTransactionsFilter: allTransactionsFilter,
    transactionsChartData: transactionsChartDataFilterReducer,
    transactionsChartStats: transactionsChartFilterReducer,

    //  dashboard reducrer

    dasboardProducts: getProductsDashboardReducer,
    dashboardLocations: getlocationsDashboardReducer,
    // notifications
    notifications: getNotificationsReducer,
    notificationsCount: getNotificationsCountsReducer,

    // admin reducers

    AdminFaq: getAdminFaqReducer,
    AdminEmployes: getAdminEmployesReducer,


    AdminFilter: getAdminEmployesFilterReducer,
    AdminLocationsQr: getAdminLocationsQrReducer,
    AdminLocationsQrFilter: getAdminLoationsQrFilterReducer,




    AdminProducts: getAdminProductsReducer,
    AdminProductsFilter: getAdminProductsFilterReducer,



    AdminLocations: getAdminLocationsReducer,
    AdminLocationsFilter: getAdminLocationsFilterReducer,
    AdminChartLocationFilter: adminLocationChartFilterReducer,
    AdminLocationChartFilter: adminLocationChartDataFilterReducer,
    AdminLocationWithStats: getAdminSingleLocationWithStatsReducer,


    AdminSubscribers: getAdminSubscriberReducer,
    AdminSubscribersFilter: getAdminSubscribersFilterReducer,
    AdminChartSubscriberFilter: adminSubscriberChartFilterReducer,
    AdminSubscriberChartFilter: adminSubscriberChartDataFilterReducer,
    AdminSubscriberWithStats: getAdminSingleSubscriberWithStatsReducer,
    adminSubscribersPagination: adminSubscribersPaginationReducer,

    AdminAllStats: getAdminAllStatsReducer,
    AdminAllFilter: getAdminAllStatsFilterReducer,
    AdminAllSubscribersFilter: getAdminAllSubscriberStatsReducer,
    AdminDashboardProducts: getAdminProductsDashboardReducer,
    AdminDashboardSubscribers: getAdminSubscribersDashboardReducer,
    AdminDashboardLocation: getAdminlocationsDashboardReducer,
    AdminDashboardPlans: getAdminPlansDashboardReducer,
    AdminDashboardPlansTrail: getAdminPlansTrailDashboardReducer,
    AdminDashboardAllFilter: getAdminDashboardFilterReducer,

    // cmd reducers 

    CMSHeader: getAdminCMSHeaderDashboardReducer,
    CMSContent: getAdminCMSContentDashboardReducer,
    CMSFooter: getAdminCMSFooterDashboardReducer,
    CMSBlogs: getAdminCMSBlogsDashboardReducer,
    CMSCount: getAdminCMSCountDashboardReducer


  });
}
  // persistReducer(persistConfig, getUserReducer)
  // persistReducer(persistConfig2, getSignUpReducer)
// }
