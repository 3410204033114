import { AnyAction } from "redux"
import { DASHBOARD_OPEN, EMPLOYEES_OPEN, LOCATIONS_OPEN, MANAGE_FAQS_OPEN, PRODUCTS_OPEN, QRCODE_OPEN, SETTINGS_OPEN, SUBSCRIBER_OPEN } from "redux/constants/admin/TopNavAdmin"

interface State{
    dashboard:boolean
    location:boolean
    products:boolean
    subscriber:boolean
    qrCode:boolean
    employees:boolean
    faqs:boolean
    settings:boolean
}

const defaultState:State = {
    dashboard:true,
    location:false,
    products:false,
    subscriber:false,
    qrCode:false,
    employees:false,
    faqs:false,
    settings:false,
}

export const TopNavAdminOpenerReducer = ((state:State = defaultState, action:AnyAction):State => {
    switch(action.type){
        case DASHBOARD_OPEN:
            return{
                dashboard:true,
                location:false,
                products:false,
                subscriber:false,
                qrCode:false,
                employees:false,
                faqs:false,
                settings:false,
            }
        case SUBSCRIBER_OPEN:
            return{
                dashboard:false,
                subscriber:true,
                location:false,
                products:false,
                qrCode:false,
                employees:false,
                faqs:false,
                settings:false,
            }
        case LOCATIONS_OPEN:
            return{
                dashboard:false,
                subscriber:false,
                location:true,
                products:false,
                qrCode:false,
                employees:false,
                faqs:false,
                settings:false,
            }
        case PRODUCTS_OPEN:
            return{
                dashboard:false,
                subscriber:false,
                location:false,
                products:true,
                qrCode:false,
                employees:false,
                faqs:false,
                settings:false,
            }
        case QRCODE_OPEN:
            return{
                dashboard:false,
                subscriber:false,
                location:false,
                products:false,
                qrCode:true,
                employees:false,
                faqs:false,
                settings:false,
            }
        case EMPLOYEES_OPEN:
            return{
                dashboard:false,
                subscriber:false,
                location:false,
                products:false,
                qrCode:false,
                employees:true,
                faqs:false,
                settings:false,
            }
        case MANAGE_FAQS_OPEN:
            return{
                dashboard:false,
                subscriber:false,
                location:false,
                products:false,
                qrCode:false,
                employees:false,
                faqs:true,
                settings:false,
            }
        case SETTINGS_OPEN:
            return{
                dashboard:false,
                subscriber:false,
                location:false,
                products:false,
                qrCode:false,
                employees:false,
                faqs:false,
                settings:true,
            }
        default: 
            return state;
    }   
})