
import { AnyAction } from "redux"
import { SALES_DATA_FAIL, SALES_DATA_REQ, SALES_DATA_SUCC } from "redux/actions/product/type"
 

interface State {
    loading?: boolean
    data?: any,
    error?: string,
     

}

const defaultState: State = {
    loading: false,
    data: {
        "sales": 0,
        "earnings": 0,
        "productCount": 0
    },
    

}

 

export const getSalesStatus = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case SALES_DATA_REQ:

            return {
                loading: true,
                ...state
            }
        case SALES_DATA_SUCC:
            
            return {
                loading: false,
                data: action.payload,
                 
            }
        case SALES_DATA_FAIL:
            return {
                loading: false,
                error: action.payload
            }
 

        default:
            return state;
    }
}