
import { Customer, FakeCoustmers } from "demo/Customers"
import { AnyAction } from "redux"
import { GET_SINGLE_SELECTED_CUSTOMER_FAIL, GET_SINGLE_SELECTED_CUSTOMER_REQ, GET_SINGLE_SELECTED_CUSTOMER_SUCC } from "redux/constants/Customer"
import { GET_SINGLE_SELECTED_LOCATION_FAIL, GET_SINGLE_SELECTED_LOCATION_REQ, GET_SINGLE_SELECTED_LOCATION_SUCC } from "redux/constants/Location"


interface State {
    loading: boolean
    data?:any
    error?:string,
    customer?:any
}

const defaultState :State = {
    loading:false,
    data:null,
    customer:null
}

export const getSingleCustomerReducer = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case GET_SINGLE_SELECTED_CUSTOMER_REQ:
            return {
                loading:true,
                data:state.data,
                customer:state.customer
            }
        case GET_SINGLE_SELECTED_CUSTOMER_SUCC:
            return {
                loading:false,
                data:action.payload,
                customer:action.data
            }
        case GET_SINGLE_SELECTED_CUSTOMER_FAIL:
            return {
                loading: false,
                error:action.payload
            }

        default:
            return state;
    }
}