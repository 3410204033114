import { Product } from "demo/FakeProduct"
import { AnyAction } from "redux"
import { CART_ADD_ITEM, EMPTY_CART } from "redux/constants/customer/CartCustomer"

interface CartItems {
    quantity: any
    item?: any,
    qty: number
}


export const cartReducer = (state: CartItems[] = [], action: AnyAction) => {
    switch (action.type) {
        case CART_ADD_ITEM:
            console.log('state', state);

            const product = action.payload.data

            const existItem = state.filter((x, i) => {

                return (x.item?._id === product._id)
            })[0]
            console.log('existItem', existItem);
            if (existItem) {
                if (action.typeOfAction === "substract") {
                    
                    existItem.qty = existItem.qty! - 1
                } else {
                    existItem.qty = existItem.qty! + 1
                }

                const index = state.findIndex(x => x.item._id === existItem.item?._id);
                state[index] = existItem
                return [

                    ...state,

                ]
            } else {
                return [
                    ...state,
                    { item: product, qty: 1 }
                ]
            }
            case EMPTY_CART:
                return []
        default:
            return state
    }

}