
import { AnyAction } from "redux"
import { LOCATIONS_ADMIN_DATA_FILTER_DATES } from "redux/actions/admin/filters/types";
import { ADMIN_LOCATIONS_DATA_FILTER } from "redux/actions/admin/location/type";
 

interface State {

    date: string | false,
    country: string,
    state: string,
    city: string,
    active: any,
    fiterDate: string | false


}

const defaultState: State = {
    date: false,
    country: "",
    state: "",
    city: "",
    active: { allTime: true, lastMonth: false, lastWeek: false },
    fiterDate: false





}



// export const ADMIN_EMPLOYES_DATA_FAIL="ADMIN_EMPLOYES_DATA_FAIL"
// export const ADMIN_EMPLOYES_DATA_REQ="ADMIN_EMPLOYES_DATA_REQ"
// export const ADMIN_EMPLOYES_DATA_SUCC="ADMIN_EMPLOYES_DATA_SUCC"


export const getAdminLocationsFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_LOCATIONS_DATA_FILTER:

            return {
                date: action.date,
                country: action.country,
                state: action.state,
                city: action.city,
                active: state.active,
                fiterDate: state.fiterDate


            }
    
            case LOCATIONS_ADMIN_DATA_FILTER_DATES:
           
                return {
                    active: action.active,
                    fiterDate: action.payload,
                    date: state.date,
                    country: state.country,
                    state: state.state,
                    city: state.city,
    
                }
    
    

        default:
            return state;
    }
}