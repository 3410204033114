export const PRODUCTS_DATA_CUSTOMER_FAIL="PRODUCTS_DATA_CUSTOMER_FAIL"
export const PRODUCTS_DATA_CUSTOMER_REQ="PRODUCTS_DATA_CUSTOMER_REQ"
export const PRODUCTS_DATA_CUSTOMER_SUCC="PRODUCTS_DATA_CUSTOMER_SUCC"


export const PRODUCTS_DATA_CUSTOMER_FILTER_NAME="PRODUCTS_DATA_CUSTOMER_FILTER_NAME"

export const  CUSTOMER_DATA_SAVE="CUSTOMER_DATA_SAVE"


export const CATEGORIES_DATA_CUSTOMER_FAIL="CATEGORIES_DATA_CUSTOMER_FAIL"
export const CATEGORIES_DATA_CUSTOMER_REQ="CATEGORIES_DATA_CUSTOMER_REQ"
export const CATEGORIES_DATA_CUSTOMER_SUCC="CATEGORIES_DATA_CUSTOMER_SUCC"


export const TRASACTION_DATA_CUSTOMER_FAIL="TRASACTION_DATA_CUSTOMER_FAIL"
export const TRASACTION_DATA_CUSTOMER_REQ="TRASACTION_DATA_CUSTOMER_REQ"
export const TRASACTION_DATA_CUSTOMER_SUCC="TRASACTION_DATA_CUSTOMER_SUCC"