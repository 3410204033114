
import { Subscriber } from "demo/admin/FakeSubscribers"
import { AnyAction } from "redux"
import { GET_SINGLE_SELECTED_SUBSCRIBER_FAIL, GET_SINGLE_SELECTED_SUBSCRIBER_REQ, GET_SINGLE_SELECTED_SUBSCRIBER_SUCC, RESET_SINGLE_SUBSCRIBER } from "redux/constants/admin/AdminSubscriber"



interface State {
    loading: boolean
    subcriber?:any
    products?:any
    locations?:any
    error?:string
    active:boolean
}

const defaultState :State = {
    loading: false,
    active: false,
    products:[],
    locations:[]
}

export const GetSingleSubscriberReducer = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case GET_SINGLE_SELECTED_SUBSCRIBER_REQ:
            return {
                loading:true
            }
        case GET_SINGLE_SELECTED_SUBSCRIBER_SUCC:
            return {
                loading:false,
                subcriber:action.subscriber,
                products:action.products,
                locations:action.locations,
                active: true
            }
        case GET_SINGLE_SELECTED_SUBSCRIBER_FAIL:
            return {
                loading: false,
                error:action.payload
            }

        case RESET_SINGLE_SUBSCRIBER:
            return{
                loading: false,
                subcriber:undefined,
                products:[],
                locations:[],
                active: false
            }
        default:
            return state;
    }
}