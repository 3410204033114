 
import { AnyAction } from "redux"
import { PRODUCTS_SALES_STATUS } from "redux/actions/product/type"
 
 


interface State {
    data?:string|boolean
    active?:any
}

const defaultState :State = {
    
    data:false,
    active:{allTime:true, lastMonth:false, lastWeek:false}
}

export const ProductsSalesStatus = (state:State = defaultState, action:AnyAction) => {
    switch (action.type){
        case PRODUCTS_SALES_STATUS:
            return {
                data:action.payload,
                 active:action.active
            }
        default:
            return state;
    }
}