 

export const PRODUCTS_DASHBOARD_DATA_FAIL="PRODUCTS_DASHBOARD_DATA_FAIL"
export const PRODUCTS_DASHBOARD_DATA_REQ="PRODUCTS_DASHBOARD_DATA_REQ"
export const PRODUCTS_DASHBOARD_DATA_SUCC="PRODUCTS_DASHBOARD_DATA_SUCC"


 

export const LOCATIONS_DASHBOARD_DATA_FAIL="LOCATIONS_DASHBOARD_DATA_FAIL"
export const LOCATIONS_DASHBOARD_DATA_REQ="LOCATIONS_DASHBOARD_DATA_REQ"
export const LOCATIONS_DASHBOARD_DATA_SUCC="LOCATIONS_DASHBOARD_DATA_SUCC"