
import { format } from "date-fns";
import { AnyAction } from "redux"
import { CHART_DATA_FAIL, CHART_DATA_FILTER_DATA, CHART_DATA_FILTER_DATES, CHART_DATA_FILTER_LOCATIONID, CHART_DATA_REQ, CHART_DATA_SUCC } from "redux/actions/product/type"
import { TRANSACTIONS_CHART_DATA_FAIL, TRANSACTIONS_CHART_DATA_REQ, TRANSACTIONS_CHART_DATA_SUCC } from "redux/actions/transactions/type";


interface State {
    data?: any,
    loading?: boolean,
    label?: any

}

const defaultState: State = {
    data: [],
    label: [],
    loading: false
}


// export const CHART_DATA_FAIL="CHART_DATA_FAIL"
// export const CHART_DATA_REQ="CHART_DATA_REQ"
// export const CHART_DATA_SUCC="CHART_DATA_SUCC"


export const transactionsChartDataFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case TRANSACTIONS_CHART_DATA_REQ:

            return {
                loading: true,
                data: state.data,
                label:state.label
            }
        case TRANSACTIONS_CHART_DATA_SUCC:
            return {
                loading: false,
                data: action.payload,
                label:action.label
            }
        case TRANSACTIONS_CHART_DATA_FAIL:
            return {
                error:"error",
                data:[],
                label:[]
            }



        default:
            return state;
    }
}