
import { AnyAction } from "redux"
import { FAQ_DATA_FAIL, FAQ_DATA_REQ, FAQ_DATA_SUCC, FAQ_DATA_SUCC_SEARCH } from "redux/actions/faq/type"
import { NOTIFICATIONS_DATA_FAIL, NOTIFICATIONS_DATA_REQ, NOTIFICATIONS_DATA_SUCC } from "redux/actions/notifications/type"


interface State {
    loading?: boolean
    data?: any,
    error?: string,
    search?:any


}

const defaultState: State = {
    loading: false,
    data: [],
    search:[]


}


// export const FAQ_DATA_FAIL="FAQ_DATA_FAIL"
// export const FAQ_DATA_REQ="FAQ_DATA_REQ"
// export const FAQ_DATA_SUCC="FAQ_DATA_SUCC"
export const getFaqReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case FAQ_DATA_REQ:

            return {
                loading: true,
                data: state.data,
                search:state.search
            }
        case FAQ_DATA_SUCC:

            return {
                loading: false,
                data: action.payload,
                search:action.payload

            }
        case FAQ_DATA_FAIL:
            return {
                loading: false,
                error: action.payload
            }

            case FAQ_DATA_SUCC_SEARCH:
                return {
                    error: action.payload,
                    data: state.data,
                    search: action.payload
                }


        default:
            return state;
    }
}