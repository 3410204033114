
import { AnyAction } from "redux"
import { ADMIN_CMS_BLOGS_DASHBOARD_DATA_FAIL, ADMIN_CMS_BLOGS_DASHBOARD_DATA_REQ, ADMIN_CMS_BLOGS_DASHBOARD_DATA_SUCC, ADMIN_CMS_COUNT_DASHBOARD_DATA_FAIL, ADMIN_CMS_COUNT_DASHBOARD_DATA_REQ, ADMIN_CMS_COUNT_DASHBOARD_DATA_SUCC } from "redux/actions/admin/dashboard/type"
  
interface State {
    loading?: boolean
    data?: any,
    error?: string,


}

const defaultState: State = {
    loading: false,
    data: null,


}



 
// export const ADMIN_CMS_COUNT_DASHBOARD_DATA_FAIL="ADMIN_CMS_COUNT_DASHBOARD_DATA_FAIL"
// export const ADMIN_CMS_COUNT_DASHBOARD_DATA_REQ="ADMIN_CMS_COUNT_DASHBOARD_DATA_REQ"
// export const ADMIN_CMS_COUNT_DASHBOARD_DATA_SUCC="ADMIN_CMS_COUNT_DASHBOARD_DATA_SUCC"




export const getAdminCMSCountDashboardReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_CMS_COUNT_DASHBOARD_DATA_REQ:

            return {
                loading: true,
                data: state.data
            }
        case ADMIN_CMS_COUNT_DASHBOARD_DATA_SUCC:

            return {
                loading: false,
                data: action.payload,

            }
        case ADMIN_CMS_COUNT_DASHBOARD_DATA_FAIL:
            return {
                loading: false,
                error: action.payload
            }


        default:
            return state;
    }
}