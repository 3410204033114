
import { format } from "date-fns";
import { AnyAction } from "redux"
import { ADMIN_LOCATIONS_CHART_DATA_FAIL, ADMIN_LOCATIONS_CHART_DATA_REQ, ADMIN_LOCATIONS_CHART_DATA_SUCC } from "redux/actions/admin/location/type";
 

interface State {
    data?: any,
    loading?: boolean,
    label?: any

}

const defaultState: State = {
    data: [],
    label: [],
    loading: false
}

 // export const ADMIN_LOCATIONS_CHART_DATA_FAIL="ADMIN_LOCATIONS_CHART_DATA_FAIL"


// export const ADMIN_LOCATIONS_CHART_DATA_REQ="ADMIN_LOCATIONS_CHART_DATA_REQ"


// export const ADMIN_LOCATIONS_CHART_DATA_SUCC="ADMIN_LOCATIONS_CHART_DATA_SUCC"



export const adminLocationChartDataFilterReducer = (state: State = defaultState, action: AnyAction) => {
    switch (action.type) {
        case ADMIN_LOCATIONS_CHART_DATA_REQ:

            return {
                loading: true,
                data: state.data,
                label:state.label
            }
        case ADMIN_LOCATIONS_CHART_DATA_SUCC:
            return {
                loading: false,
                data: action.payload,
                label:action.label
            }
        case ADMIN_LOCATIONS_CHART_DATA_FAIL:
            return {
                error:"error",
                data:[],
                label:[]
            }



        default:
            return state;
    }
}